<template>
  <v-dialog :value="value" :fullscreen="$vuetify.breakpoint.mobile" @click:outside="close" max-width="600" scrollable>
    <v-card :loading="loading" v-if="item">
      <v-card-title class="py-2 pr-2">
        <h5 v-if="item.routeLength !== null" class="primary--text">{{ $t('offerDetails.routeLength') }}: {{ item.routeLength}} km</h5>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="item">
        <v-row no-gutters>
          <v-col cols="4" class="text-center" align-self="center">
            <h3 class="primary--text">{{ $t('offerDetails.loading') }}</h3>
            <div v-for="(loadingPlace, index) in item.loadingPlaces" :key="index" class="mb-2">
              <div class="subtitle-1 black--text">
                {{ loadingPlace.country }},  {{ loadingPlace.place.postalCode}} {{ loadingPlace.place.city}}
              </div>
              <span class="lightText--text">{{ loadingPlace.date | dateFormat }} <span v-if="loadingPlace.from && loadingPlace.to" class="ma-0">{{ loadingPlace.from }} - {{ loadingPlace.to }}</span></span>
              <div v-if="loadingPlace.fixedTime" class="font-weight-bold">FIX</div>
            </div>
            <div v-if="isVehicle">{{ item.startDate }} {{ item.startTime }}</div>
            <div v-if="!isVehicle && item.fixedStart" class="font-weight-bold">FIX</div>
          </v-col>
          <v-col cols="4" align-self="center">
            <v-img contain height="46" class="mb-12" :src="require('@/assets/images/loads.svg')"></v-img>
          </v-col>
          <v-col cols="4" class="text-center" align-self="center">
            <h3 class="primary--text">{{ $t('offerDetails.unloading') }}</h3>
            <div v-for="(unloadingPlace, index) in item.unloadingPlaces" :key="index" class="mb-2">
              <div class="subtitle-1 black--text">
                <span>{{ unloadingPlace.country }}</span>
                <span v-if="unloadingPlace.place && (unloadingPlace.place.postalCode.length > 0 || unloadingPlace.place.city.length > 0)">, </span>
                <span v-if="unloadingPlace.place">{{ unloadingPlace.place.postalCode}} {{ unloadingPlace.place.city}}</span>
              </div>
              <span class="lightText--text">{{ unloadingPlace.date | dateFormat }} <span v-if="unloadingPlace.from && unloadingPlace.to" class="ma-0">{{ unloadingPlace.from }} - {{ unloadingPlace.to }}</span></span>
              <div v-if="unloadingPlace.fixedTime" class="font-weight-bold">FIX</div>
            </div>
            <span v-if="isVehicle" class="lightText--text">{{ item.endDate }} {{ item.endTime }}</span>
            <div v-if="!isVehicle && item.fixedEnd" class="font-weight-bold">FIX</div>
          </v-col>
        </v-row>
        <v-expansion-panels id="details" flat class="mt-4">
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header expand-icon="$sort">
              <h4 class="primary--text text-center">{{ isVehicle ? $t('offerDetails.details') : $t('offerDetails.detailsFreight') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <VehicleDetailsPanel v-if="isVehicle" :details="vehicleDetails" />
              <FreightDetailsPanel v-else :details="freightDetails" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header expand-icon="$sort">
              <h4 class="primary--text text-center">{{ $t('offerDetails.requirementsVehicle') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FreightVehicleRequirementsPanel :details="item" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header expand-icon="$sort">
              <h4 class="primary--text text-center">{{ $t('offerDetails.companyInfo') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <CompanyInfoPanel :details="companyInfo" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="isCurrentUsersOffer && item.limitedCompanies && item.limitedCompanies.length > 0" class="mb-2">
            <v-expansion-panel-header expand-icon="$sort">
              <h4 class="primary--text text-center">{{ $t('offerDetails.private') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PrivatePanel :limited-companies="item.limitedCompanies" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="isCurrentUsersOffer && item.excludedCompanies && item.excludedCompanies.length > 0" class="mb-2">
            <v-expansion-panel-header expand-icon="$sort">
              <h4 class="primary--text text-center">{{ $t('offerDetails.excluded') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PrivatePanel :limited-companies="item.excludedCompanies" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header expand-icon="$sort">
              <h4 class="primary--text text-center">{{ $t('offerDetails.map') }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MapPanel :details="mapInfo" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions v-if="!isCurrentUsersOffer" class="d-flex justify-center">
          <v-btn color="primary" depressed class="px-4" @click="openChat(item.publisher.id)">
            {{ $t('offerDetails.sendOffer') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <v-progress-circular
        v-else
        indeterminate
        class="mx-auto my-12"
        color="primary"
        size="100"
      ></v-progress-circular>
    </v-card>
  </v-dialog>
</template>

<script>
import FreightDetailsPanel from './panels/FreightDetailsPanel.vue'
import FreightVehicleRequirementsPanel from './panels/FreightVehicleRequirementsPanel'
import CompanyInfoPanel from './panels/CompanyInfoPanel.vue'
import VehicleDetailsPanel from './panels/VehicleDetailsPanel.vue'
import PrivatePanel from '@/views/Market/dialogs/panels/PrivatePanel'
import MapPanel from '@/views/Market/dialogs/panels/MapPanel'
import { Details as ClickDetails, Chat as ClickChat } from '@/types/click-order-types.js'

import { startConversation } from '@/signalr/chat'

import Service from '@/services'

export default {
  name: 'OfferDetailsDialog',
  components: {
    FreightDetailsPanel,
    FreightVehicleRequirementsPanel,
    CompanyInfoPanel,
    VehicleDetailsPanel,
    PrivatePanel,
    MapPanel
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    itemId: {
      type: String,
      required: true
    },
    isVehicle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vehicleDetails () {
      return {
        size: this.item.size,
        type: this.item.type,
        load: this.item.load,
        loadForms: this.item.loadForms,
        loadMeters: this.item.loadMeters,
        height: this.item.height,
        width: this.item.width,
        length: this.item.length,
        volume: this.item.volume,
        publisher: this.item.publisher.fullName,
        publisherId: this.item.publisher.id,
        price: this.item.price,
        currency: this.item.currency,
        options: [
          ...this.item.options
        ],
        additionalDescription: this.item.additionalDescription
      }
    },
    freightDetails () {
      return {
        name: this.item.freightName,
        length: this.item.freightLength,
        width: this.item.freightWidth,
        height: this.item.freightHeight,
        volume: this.item.freightVolume,
        weight: this.item.freightWeight,
        amount: this.item.freightAmount,
        price: this.item.price,
        currency: this.item.currency,
        freightType: this.item.freightType.join(', '),
        vehicleFreightTypes: this.item.vehicleFreightTypes,
        additionalDescription: this.item.additionalDescription,
        publisher: this.item.publisher.fullName,
        publisherId: this.item.publisher.id,
        loadMeters: this.item.loadMeters,
        daysForPayment: this.item.daysForPayment
      }
    },
    companyInfo () {
      return {
        companyId: this.item.companyId,
        companyName: this.item.companyName,
        displayId: this.item.displayId,
        vatNumber: this.item.vatNumber,
        street: this.item.street,
        city: this.item.city,
        zipCode: this.item.zipCode,
        country: this.item.country,
        averageRating: this.item.averageRating,
        averagePaymentRating: this.item.averagePaymentRating,
        canAddReview: this.item.canAddReview,
        documentNames: [
          ...this.item.documentNames
        ]
      }
    },
    mapInfo () {
      return {
        unloadingPlaces: this.item.unloadingPlaces,
        loadingPlaces: this.item.loadingPlaces
      }
    },
    isCurrentUsersOffer () {
      return this.$store.getters.userCommonData.companyId === this.item.companyId
    }
  },
  data () {
    return {
      ratingDialogActive: false,
      loading: false,
      item: null
    }
  },
  async mounted () {
    await this.getDetails()
    await this.track()
  },
  methods: {
    async getDetails () {
      if (this.isVehicle) {
        const { data } = await Service.get(`/VehicleOrder/GetDetails?orderId=${this.itemId}`)
        this.item = data
      } else {
        const { data } = await Service.get(`/FreightOrder/GetDetails?orderId=${this.itemId}`)
        this.item = data
      }
    },
    close () {
      this.$emit('closed')
    },
    async openChat (userId) {
      const data = {
        orderId: this.item.id,
        clickType: ClickChat
      }

      await Service.post('/order/userorderclickstatistic', data)
      startConversation(userId, this.itemId)
      this.close()
    },
    async track () {
      const data = {
        orderId: this.item.id,
        clickType: ClickDetails
      }

      await Service.post('/order/userorderclickstatistic', data)
    }
  }
}
</script>
<style>
#details .v-expansion-panel
{
  border: solid thin var(--v-primary-base);
  border-radius: 5px;
}
</style>
